import React, { useCallback, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { H5 } from '@styles/Typography';

import { Wrapper, Section } from '@components/Layout/styles';

import { SearchIcon } from '@components/Icons';
import SEO from '@components/seo';
import Breadcrumb from '@components/Breadcrumb';
import CTA from '@components/CTAS/Appointment';
import InnerBanner from '@components/InnerBanner';
import Sidebar from '@components/Sidebars/Main';
import Alert from '@components/Alert';
import TextField from '@components/TextField';

import * as S from './_styles';

const MedicamentosPage = props => {
	const [filteredDrugList, setFilteredDrugList] = useState([]);
	const [term, setTerm] = useState('');

	const { drugs, meta, pageData } = useStaticQuery(graphql`
		{
			meta: allCosmicjsMeta(filter: { slug: { eq: "meta-drugs" } }) {
				nodes {
					metadata {
						title
						description
						cover {
							imgix_url
						}
					}
				}
			}

			pageData: allCosmicjsPages(filter: { slug: { eq: "drugs" } }) {
				nodes {
					metadata {
						description
						title
						banner {
							imgix_url
						}
						alert {
							title
							content
						}
					}
				}
			}

			drugs: allCosmicjsDrugs(sort: { order: ASC, fields: slug }) {
				nodes {
					slug
					metadata {
						name
						factory
						commercial_name
					}
				}
			}
		}
	`);

	const drugList = drugs.nodes;

	const {
		metadata: { title: pageTitle, description: pageDescription, banner, alert },
	} = pageData.nodes[0];

	const {
		metadata: { title: metaTitle, description: metaDescription, cover },
	} = meta.nodes[0];

	const normalize = str =>
		str
			.normalize('NFD')
			.replace(/[^a-zA-Zs]/g, '')
			.toLowerCase();

	const filterCondition = useCallback(() => {
		const normalizedTerm = normalize(term);

		setFilteredDrugList(
			drugList.filter(
				({ metadata: { name, commercial_name } }) =>
					normalize(name).includes(normalizedTerm) ||
					normalize(commercial_name).includes(normalizedTerm)
			)
		);
	}, [drugList, term]);

	const filterMatches = useCallback(
		letters => {
			const regex = new RegExp(`^[${letters}]`, 'i');

			setFilteredDrugList(
				drugList.filter(
					({ metadata: { name, commercial_name } }) =>
						regex.test(name) || regex.test(commercial_name)
				)
			);
		},
		[drugList]
	);

	useEffect(() => {
		filterCondition();
	}, [filterCondition]);

	useEffect(() => {
		setFilteredDrugList(drugList);
	}, [setFilteredDrugList, drugList]);

	useEffect(() => {
		if (props.pageContext.slug) {
			setTimeout(() => {
				const scrollItem =
					document.getElementById(props.pageContext.slug).offsetTop - 130;

				window.scrollTo({ top: scrollItem, behavior: 'smooth' });
			}, 1);
		}
	});

	const onChangeAccordionItem = item => {
		window.history.pushState({}, document.title, `/medicamentos/${item.id}`);
	};

	return (
		<>
			<SEO
				title={metaTitle || 'Medicamentos'}
				description={metaDescription}
				cover={cover.imgix_url}
			/>

			<InnerBanner
				title={pageTitle}
				description={pageDescription}
				bg={banner?.imgix_url}
			/>

			<Wrapper>
				<Breadcrumb
					crumbs={[{ title: 'Medicamentos', slug: 'medicamentos' }]}
				/>
			</Wrapper>

			<Section>
				<S.Wrapper>
					<S.MainContent>
						<Alert title={alert.title} content={alert.content} />

						<S.SearchBox>
							<S.InputSearch>
								<H5>Procure por um medicamento</H5>
								<TextField
									onChange={e => setTerm(e.target.value)}
									name="drugs"
									placeholder="Digite aqui um medicamento"
									prefix={<SearchIcon />}
									value={term}
								/>
							</S.InputSearch>

							<S.SearchByLetters
								list={drugList.nodes}
								handleFilter={filterMatches}
							/>
						</S.SearchBox>

						{filteredDrugList.map(
							(
								{ slug, metadata: { name, commercial_name, factory } },
								index
							) => {
								return (
									<S.Collapse
										title={`${name}<small> / ${commercial_name}</small>`}
										id={`${slug}`}
										type="unique"
										key={`${name}-${index}`}
										checked={slug === props.pageContext.slug}
										onChange={onChangeAccordionItem}
									>
										<div>
											<b>Nome comercial</b>
											{commercial_name}
										</div>
										<div>
											<b>Fabricante</b>
											{factory}
										</div>
									</S.Collapse>
								);
							}
						)}

						<CTA />
					</S.MainContent>

					<Sidebar />
				</S.Wrapper>
			</Section>
		</>
	);
};

MedicamentosPage.propTypes = {
	pageContext: PropTypes.shape({
		slug: PropTypes.string,
	}).isRequired,
};
export default MedicamentosPage;
