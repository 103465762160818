import styled from 'styled-components';
import { spacing } from '@styles/Theme';
import { H5 } from '@styles/Typography';

export const Container = styled.div`
	margin-bottom: ${spacing(4)};
	padding: ${spacing(3)};
	background-color: #f4f4f4;
	border-radius: ${spacing(2)};
`;

export const Title = styled(H5)`
	display: flex;
	align-items: center;

	svg {
		margin-right: ${spacing(0.5)};
		color: #e02924;
	}
`;
