import React from 'react';
import PropTypes from 'prop-types';
import { P } from '@styles/Typography';
import { ErrorIcon } from '@components/Icons';

import * as S from './styles';

function Alert({ title, content }) {
	return (
		<S.Container>
			<S.Title>
				<ErrorIcon size={24} />
				{title}
			</S.Title>
			<P>{content}</P>
		</S.Container>
	);
}

Alert.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
};

export default Alert;
